import up from 'unpoly';

import { $ } from '../../../../utils/dom';

const dcn = (suffix = '') => `.view-components-layout-Expandable${suffix}`;

up.compiler(dcn(), ($el) => {
    const $anchor = $(dcn('-anchor'), $el);

    const $checkbox = $(dcn('-checkbox'), $el);

    const handleCheckboxChange = () => {
        if (!$anchor) {
            return;
        }

        if (!$checkbox.checked) {
            return;
        }

        const { id } = $anchor;

        $anchor.id = null;

        window.location.hash = `#${id}`;

        setTimeout(() => {
            $anchor.id = id;
        });
    };

    const handleHashChange = () => {
        const hash = window.location.hash.slice(1);

        if (!hash) {
            return;
        }

        const $target = document.getElementById(hash);

        if (hash === $anchor?.id || $el.contains($target)) {
            $checkbox.checked = true;
        }
    };

    $checkbox.addEventListener('change', handleCheckboxChange);
    window.addEventListener('popstate', handleHashChange);

    handleHashChange();
});
