import up from 'unpoly';

import { $, $$ } from '../../../utils/dom';

const dcn = (suffix = '') => `.wp-block-berghs-master-search${suffix}`;

up.compiler(dcn(), ($el) => {
    const $hiddenSectionInput = $(dcn('__hidden-section-input'), $el);
    const $hiddenTypeInput = $(dcn('__hidden-type-input'), $el);

    const $sections = $$(dcn('__section'), $el);
    const $sectionsBySlug = $sections.reduce((acc, $section) => {
        acc[$section.dataset.slug] = $section;

        return acc;
    }, {});
    const $typeInputs = $$(dcn('__type-input'), $el);

    const $sectionLinks = $$(dcn('__section-link'), $el);

    const $courseList = $(dcn('__course-list'), $el);
    const $courseListLinks = $$(dcn('__course-list-link'), $el);
    const $coursesTemplate = $(dcn('__courses-template'), $el);
    const $coursesContainer = $(dcn('__courses-container'), $el);
    let courses = [];

    let currentSectionSlug = 'root';
    let currentFormat = null;
    let currentType = '*';

    const loadCourses = () => {
        if (courses.length > 0) {
            return;
        }

        $coursesContainer.innerHTML = $coursesTemplate.innerHTML;
        courses = $$(dcn('__course'), $el).map(($course) => ({
            $el: $course,
            formats: JSON.parse($course.dataset.formats),
            types: JSON.parse($course.dataset.types),
        }));
    };

    const shouldShowCourse = (course) => {
        if (!currentFormat) {
            return true;
        }

        if (!course.formats.includes(currentFormat)) {
            return false;
        }

        if (currentType === '*') {
            return true;
        }

        if (course.types.includes(currentType)) {
            return true;
        }

        return false;
    };

    const toggleCourses = () => {
        courses.forEach((course) =>
            course.$el.classList.toggle('hidden', !shouldShowCourse(course)),
        );
    };

    const updateCourseListLink = () => {
        if (!currentFormat) {
            $courseListLinks.forEach(($l) => {
                $l.href = '#';
            });

            return;
        }

        let link = `/kurslista/?berghs_format=${currentFormat}`;

        if (currentType !== '*') {
            link += `&berghs_type=${currentType}`;
        }

        $courseListLinks.forEach(($l) => {
            $l.href = link;
        });
    };

    const updateTypeInputs = () => {
        $typeInputs.forEach(($i) => {
            $i.checked = $i.value === `${currentType}`;
        });
    };

    const setSection = (nextSectionSlug, track = true) => {
        if (nextSectionSlug === currentSectionSlug) {
            return;
        }

        const $oldSection = $sectionsBySlug[currentSectionSlug];
        const $nextSection = $sectionsBySlug[nextSectionSlug];

        $oldSection.classList.add(
            'absolute',
            'left-0',
            'top-0',
            'opacity-0',
            'pointer-events-none',
        );
        $nextSection.classList.remove(
            'absolute',
            'left-0',
            'top-0',
            'opacity-0',
            'pointer-events-none',
        );

        currentSectionSlug = nextSectionSlug;
        currentFormat = $nextSection.dataset.courseFormat
            ? parseInt($nextSection.dataset.courseFormat, 10)
            : null;
        currentType = '*';

        $hiddenSectionInput.value = currentSectionSlug;
        $hiddenTypeInput.value = currentType;

        if (currentFormat) {
            loadCourses();

            updateCourseListLink();
            toggleCourses();
            updateTypeInputs();

            $courseList.classList.remove('hidden');
        } else {
            $courseList.classList.add('hidden');
        }

        if (track && window.ga) {
            window.ga('send', 'event', 'MasterSearch', 'open', nextSectionSlug);
        }

        if (track && window.dataLayer) {
            window.dataLayer.push({
                event: 'MasterSearch',
                name: 'open',
                value: nextSectionSlug,
            });
        }
    };

    const setType = (nextType, track = true) => {
        if (currentType === nextType) {
            return;
        }

        if (nextType !== '*') {
            nextType = parseInt(nextType, 10);
        }

        currentType = nextType;
        $hiddenTypeInput.value = nextType;

        updateCourseListLink();
        toggleCourses();
        updateTypeInputs();

        if (track && window.ga) {
            window.ga('send', 'event', 'MasterSearch', 'filter', nextType);
        }

        if (track && window.dataLayer) {
            window.dataLayer.push({
                event: 'MasterSearch',
                name: 'filter',
                value: nextType,
            });
        }
    };

    const handleSectionLinkClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const { section } = event.currentTarget.dataset;

        setSection(section);
    };

    const handleTypeInput = (event) => {
        setType(event.currentTarget.value);
    };

    const restoreState = () => {
        const prevSection = $hiddenSectionInput.value;
        const prevType = $hiddenTypeInput.value;

        if (prevSection) {
            setSection(prevSection, false);
        }
        if (prevType) {
            setType(prevType, false);
        }
    };

    $sectionLinks.forEach(($a) => {
        $a.addEventListener('click', handleSectionLinkClick);
    });

    $typeInputs.forEach(($i) => {
        $i.addEventListener('change', handleTypeInput);
    });

    if (document.readyState === 'complete') {
        restoreState();
    } else {
        window.addEventListener('load', () => {
            setTimeout(restoreState);
        });
    }
});
