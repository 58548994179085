import './sentry';
import 'container-query-polyfill';
import up from 'unpoly';
import 'unpoly/unpoly-migrate';
import '../macros/imbox-open-form-links';
import '../compilers';
import './real-100vw';
import './tracking';
import './form-submit-handlers';

import './views/blocks/animated-banner';
import './views/blocks/countdown';
import './views/blocks/header-video';
import './views/blocks/master-search';
import './views/blocks/pardot-form-solutions-2';
import './views/blocks/sticky-menu';
import './views/blocks/time-edit-schedule';
import './views/components/blocks/course-filter';
import './views/components/blocks/newsletter-signup';
import './views/components/blocks/pardot-form';
import './views/components/blocks/sticky-cta-banner';
import './views/components/blocks/testimonials-slider';
import './views/components/boxes/box-email-form';
import './views/components/elements/lottie';
import './views/components/layout/expandable';
import './views/components/misc/fee-currency-converter';
import './views/components/misc/reveal-button';
import './views/components/site/site-header';
import './views/templates/page-application';
import './views/templates/page-checkout-stripe';
import './views/templates/page-quiz';
import './views/templates/page-screen';

// Remove the old berghs_user_consent cookie
document.cookie =
    'berghs_user_consent=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';

up.fragment.config.runScripts = true;
up.history.config.updateMetaTags = true;
up.log.config.format = false;
up.log.enable();
